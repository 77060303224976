<template>
  <div>
    <MobileModal
      v-if="mobile"
      v-bind="$attrs"
    >
      <slot />
    </MobileModal>
    <DesktopModal
      v-else
      v-bind="$attrs"
    >
      <slot />
    </DesktopModal>
  </div>
</template>

<script setup>

const { mobile } = useDisplay();

</script>
