<style lang="scss" scoped>
  .v-dialog {
    margin-top: 20%;
  }

  .v-card {
    border-top-left-radius: 2.5rem !important;
    border-top-right-radius: 2.5rem !important;
  }

  .v-card-text {
    font-size: initial !important;
    line-height: initial !important;
    letter-spacing: initial !important;
  }

  .v-icon {
    font-size: 2rem;
  }
</style>

<template>
  <v-dialog
    :model-value="visible"
    scrollable
    transition="dialog-bottom-transition"
    fullscreen
    v-bind="$attrs"
    :persistent="persistent"
    @update:model-value="onClose"
  >
    <v-card>
      <div class="mt-6 mx-4 text-right">
        <v-icon v-if="!persistent" icon="mdi-close" @click="onClose" />
      </div>
      <v-card-text>
        <slot />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script setup>

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  persistent: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:modelValue']);

const visible = ref(props.modelValue);

watch(() => props.modelValue, function (newValue) {
  visible.value = newValue;
});

function onClose () {
  visible.value = false;
  emit('update:modelValue', false);
}

</script>
