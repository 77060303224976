<style lang="scss" scoped>
  .v-card {
    border-radius: 0.5rem !important;
  }

  .v-card-text {
    font-size: initial !important;
    line-height: initial !important;
    letter-spacing: initial !important;
  }
</style>

<template>
  <v-dialog
    :model-value="visible"
    scrollable
    v-bind="$attrs"
    :persistent="persistent"
    width="500"
    height="500"
    @update:model-value="onClose"
  >
    <v-card>
      <div v-if="!persistent" class="ma-4 text-right">
        <v-icon icon="mdi-close" @click="onClose" />
      </div>
      <v-card-text>
        <slot />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script setup>

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  persistent: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:modelValue']);

const visible = ref(props.modelValue);

watch(() => props.modelValue, function (newValue) {
  visible.value = newValue;
});

function onClose () {
  visible.value = false;
  emit('update:modelValue', false);
}

</script>
